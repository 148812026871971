
<script>


/**
 * Transfer Dialog component
 */


import simplebar from "simplebar-vue";

import { getCashApi } from '@/api/cash'

export default {

    emits: ['confirm', 'cancel'],

    props: ['transactions'],
    components: {
        simplebar
    },

    validations: {
       
    },



    data() {
        return {
            transaction_number: '',
            total_amount : 0,
            description  : '',
            remit_bank_account: {
                name : ''
            }, 
            receive_bank_account : {
                name : ''
            },
        }
    },

    methods: {
        formSubmit() {

            this.$v.$touch();
            if (this.$v.$error == false) {
                this.make_transfer()
            }
        },

        make_transfer() {
            let data = {
                transactions: [],
                description : this.description
            }

            this.transactions.map(r => {
                data.transactions.push({
                    id : r.id,
                    amount : r.amount,
                    remit_bank_id : r.from.id,
                    receive_bank_id : r.to.id
                })
            })
            getCashApi().make_transfer(data).then((res) => {
                if (res.errCode == 0) {
                    this.$emit('confirm')
                } else {
                    this.$alertify.error("Transfer Failed " + res.errCode);
                }
            })
        }

    },

    created() {
    },

    mounted() {

        if (this.transactions && this.transactions.length > 0) {
            this.remit_bank_account = this.transactions[0].from
            this.receive_bank_account = this.transactions[0].to
            this.total_amount = this.transactions.reduce((a, c) => a += c.amount, 0)
        }
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom01">From</label>
                            <input id="validationCustom01"  type="text" class="form-control"
                                placeholder="" readonly :value="remit_bank_account.name" />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom01">To</label>
                            <input id="validationCustom01"  type="text" class="form-control"
                                placeholder="" readonly :value="receive_bank_account.name" />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom01">Amount</label>
                            <input id="validationCustom01"  type="text" class="form-control"
                                placeholder="" readonly :value="total_amount" />
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-2">
                        <div class="mb-3">
                            <label for="validationCustom01">Code </label>
                            <input id="validationCustom01" v-model="transaction_number" type="text" class="form-control"
                                placeholder=""  maxlength="9" />
                           
                        </div>
                    </div>

                    <div class="col-md-10">
                        <div class="mb-3">
                            <label for="validationCustom01">Description </label>
                            <input id="validationCustom01" v-model="description" type="text" class="form-control"  placeholder=""  />
                           
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <simplebar class="table-container" data-simplebar-auto-hide="false">
                    <div class="table-responsive" style="max-height: 400px;">
                        <div class="table align-middle table-nowrap" style="height:100%" >
                            <b-table :items="transactions" :fields="['Amount', 'Date','Ref','Description']" responsive="sm" :per-page="transactions.length" 
                            class="table-check">

                                <template #cell(Amount)="data">
                                    ${{ data.item.amount.toLocaleString() }}
                                </template>
                                <template #cell(Date)="data">
                                    {{ data.item.transaction_date}}
                                </template>
                                <template #cell(Ref)="data">
                                    {{ data.item.reference}}
                                </template>
                                <template #cell(Description)="data">
                                    {{ data.item.remarks }}
                                </template>
                            </b-table>
                        </div>
                    </div>
                </simplebar>


                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>