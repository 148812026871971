
<script>


/**
 * Transfer Dialog component
 */



import { getCashApi } from '@/api/cash'
import Choices from "choices.js";

export default {

    emits: ['confirm', 'cancel'],

    props: [],
    components: {
        
    },

    validations: {
       
    },



    data() {
        return {
            remit_bank_account_choice : {},
            reveive_bank_account_choice: {},
            transaction_number   : '',
            total_amount         : 0,
            description          : '',
            remit_bank_account   : '',
            receive_bank_account : '',
            account_list         : [],
            
        }
    },

    methods: {
        formSubmit() {

            this.$v.$touch();
            if (this.$v.$error == false) {
                this.make_transfer()
            }
        },

        make_transfer() {
            let data = {
                amount      : this.total_amount,
                rmit_bank   : this.remit_bank_account,
                reve_bank   : this.receive_bank_account,
                description : this.description,
            }

            getCashApi().make_manual_transfer(data).then((res) => {
                if (res.errCode == 0) {
                    this.$emit('confirm')
                    this.$alertify.message("Transfer Successfully ");
                } else {
                    this.$alertify.error("Transfer Failed " + res.errCode);
                }
            })
        }

    },

    created() {
    },

    mounted() {

        this.remit_bank_account_choice = new Choices("#remit_bank_account")
        this.reveive_bank_account_choice = new Choices("#reveive_bank_account")
        
        getCashApi().bank_account_list().then((res) =>{
            this.account_list.push({
                label: "",
                account: ""
            })
            if (res.errCode == 0) {
                res.data.accounts.map((a)=>{
                this.account_list.push({
                    label : a.name,
                    value : a.id,
                    account : a.a_account
                })
                })
                this.remit_bank_account_choice.setChoices(this.account_list)
                this.reveive_bank_account_choice.setChoices(this.account_list)
            }
        })
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom01">From</label>
                            <select class="form-control" data-trigger  v-model="remit_bank_account"  name="account"  id="remit_bank_account"  >
                                <option value=" "></option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom01">To</label>
                            <select class="form-control" data-trigger  v-model="receive_bank_account" name="account"  id="reveive_bank_account"  >
                                <option value=" "></option>
                            </select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom01">Amount</label>
                            <input id="validationCustom01"  type="text" class="form-control"
                                placeholder=""  v-model="total_amount"/>
                        </div>
                    </div>

                </div>
                <!-- end row-->

                <div class="row">
                    <div class="col-md-2">
                        <div class="mb-3">
                            <label for="validationCustom01">Code </label>
                            <input id="validationCustom01" v-model="transaction_number" type="text" class="form-control"
                                placeholder=""  maxlength="9" />
                           
                        </div>
                    </div>

                    <div class="col-md-10">
                        <div class="mb-3">
                            <label for="validationCustom01">Description </label>
                            <input id="validationCustom01" v-model="description" type="text" class="form-control"  placeholder=""  />
                           
                        </div>
                    </div>
                </div>
                <!-- end row-->



                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>